import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Amplify, * as AmplifyModules from 'aws-amplify'
import '@aws-amplify/ui-vue'
import awsconfig from './aws-exports'
//import ja from "@aws-amplify-jp/vocabulary-ja";
import { I18n } from '@aws-amplify/core';
import { vocabularies } from './assets/vocabularies.js';

//import { Translations } from "@aws-amplify/ui-components";
import vuetify from './plugins/vuetify'
import '@/assets/scss/common.scss'
Amplify.configure(awsconfig)

Vue.use( AmplifyModules)

Vue.config.productionTip = false
I18n.putVocabulariesForLanguage('ja', vocabularies);
let languageDict = {
    ja:{
        'Sign In Account' : 'アカウントにログイン',
        'Username' : 'ID',
        'Enter your Username': 'IDを入力してください',
        'Password' : 'パスワード',
        'Enter your password' : 'パスワードを入力してください',
        'Forgot your password? ':'パスワードを忘れた場合',
        'Reset password' : 'パスワードのリセット',
        'No account? ' : 'まだアカウントがない場合',
        'Create account' : 'アカウント作成',
        'Sign In' : 'ログイン',
        'Code' : '認証コード',
        'New Password' : '新しいパスワードを入力してください（8文字以上の大文字小文字を含む英数字）',
        'Enter new password':'新しいパスワードを入力してください',
    }
}
AmplifyModules.I18n.putVocabularies(languageDict)//英語のままの部分を日本語に上書き


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
