<template>
  <div class="login ma-4 text-center">
    <div class="inner-box text-l">
      <p>ヒューマンアカデミーフィッシングカレッジの学生さん限定！</p>
      <p>海ナビを含むお天気ナビゲータの全有料サービス(月額288円相当)が使える無料トライアルアカウントをプレゼント！</p>
      <p>以下をご確認の上、ログインへお進みください。</p>

      <h2 class="cmn-sttl2 mt40">トライアル期間</h2>
      <div>ご利用手続き受付期間</div>
      <p class="text-bold">2024年7月17日～8月31日</p>
      <div>無料トライアル期間</div>
      <p class="text-bold">初回ログイン完了後～2024年10月31日</p>

      <h2 class="cmn-sttl2 mt40">注意事項</h2>
      <p>1.無料トライアル期間終了後、アカウントは削除されます。引き続きご利用いただくには、お天気ナビゲータへの有料会員登録が必要です。</p>
      <p>2.無料トライアルアカウントのご利用手続きは、お1人様1回限りとなります。</p>
      <p>3.ご利用手続きを行わないまま受付期間を過ぎると、無料トライアルはご利用いただけません。お早めにお済ませください。</p>
    </div>
      

    <!--ログインコンポーネント-->
    <amplify-authenticator v-bind:authConfig="authConfig"></amplify-authenticator>

    <div class="inner-box text-l">
      <p>※記入いただいたメールアドレスは、無料トライアル期間終了のお知らせや、アンケートの案内をお送りすることがございます。弊社(日本気象株式会社)のみで使用し、第三者に渡すことはございません。</p>
      <p>※「@n-kishou.co.jp」を含むアドレスからのメールを受信できるものをご記入ください。</p>
      <p>※連続した「.」を含むアドレスや@の直前に「.」の入ったアドレスは、ご利用いただけません。</p>
    </div>
  </div>
</template>

<script>

    export default {
        name: 'Login',
        data(){
    return{
      authConfig: {
        signUpConfig: {
          defaultCountryCode: "44",
          hiddenDefaults: ['phone_number']
        },
        forgotPasswordConfig:{
            header: 'パスワードのリセット',
        },
         newFormFields: [
        {
          type: 'email',
          label: 'Custom Email Label',
          placeholder: 'Custom email placeholder',
          inputProps: { required: true, autocomplete: 'username' },
        },
        {
          type: 'password',
          label: 'Custom Password Label',
          placeholder: 'Custom password placeholder',
          inputProps: { required: true, autocomplete: 'new-password' },
        },
        {
          type: 'phone_number',
          label: 'Custom Phone Label',
          placeholder: 'Custom phone placeholder',
        },
      ],
    signInConfig: {
            isSignUpDisplayed: false,
            header: 'ログイン',
        }
      }
    }
}
    }
</script>
