/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:62f671df-5d3f-4741-861e-1dac15d95a16",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_wh2PKxdyw",
    "aws_user_pools_web_client_id": "6ja9kraj6u8mfl6edb24plq4qc",
    "oauth": {
        "domain": "jmcaccountfda18f41-fda18f41-dev.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://s.n-kishou.co.jp/wtest/charge/test/login_hirose.php/",
        "redirectSignOut": "https://s.n-kishou.co.jp/wtest/charge/test/login_hirose.php/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
