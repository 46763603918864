<template>
  <v-app>
    <header id="head">
      <div id="head-nav-wrap">
        <div class="cmn-inner fbox">
          <p id="head-logo" class="fl">
            <a href=""><img src="https://cdn1.n-kishou.co.jp/image/common/logo.png" alt="お天気ナビゲータ" width="279" height="62"></a>
          </p>
        </div>
      </div>
    </header>

    <v-main>
      <router-view/>
    </v-main>
    
    <footer id="foot">
      <div class="foot-contents normal"></div>
      <div class="inner-foot normal">
        <div class="cmn-inner season-footer season"></div><!-- // .cmn-inner -->
        <div class="cmn-inner fbox">
          <p class="foot-copy fl notranslate">(C) 日本気象株式会社</p>
        </div><!-- // .cmn-inner fbox -->
      </div>
    </footer>
  </v-app>
</template>


<script>

export default {
  
  name: 'App',

  data: () => ({
    //
  }),
  
  created() {
    this.setViewport();
  },
  methods: {
    
    setViewport: function() {
      let changeWindowSize = 960
      let viewportContent = "user-scalable=no"
      if (window.innerWidth < changeWindowSize ) {
        viewportContent = "width=480,user-scalable=no"
      }
      document.querySelector("meta[name='viewport']").setAttribute("content", viewportContent)
    }
  }
};
</script>

<style>
:root {
  --amazonOrange: #015db2  !important;
  --darkAmazonOrange: #015db2  !important;
  --lightAmazonOrange: #015db2  !important;
  --deepSquidInk: #585858  !important;
  --amplify-primary-color: #4b1c08 !important;
  --amplify-primary-tint: #b36627 !important;
  --amplify-primary-shade: #783b17 !important;
  --amplify-font-family: "Roboto", sans-serif;
  --amplify-white: #fdfade;
  --amplify-secondary-tint: #b36627;
  --amplify-text-xxs:	1.75rem !important;
    --amplify-text-xs:	1.81rem !important;
    --amplify-text-sm: 1.875rem !important;
    --amplify-text-md:	2rem !important;
    --amplify-text-lg:	2.5rem !important;
    --amplify-text-xl:	3rem !important;
    --amplify-text-xxl: 3.5rem !important;
}

@media screen and (max-width: 960px) {
  :root {
    --amplify-text-xxs:	1.75rem !important;
    --amplify-text-xs:	1.81rem !important;
    --amplify-text-sm: 1.875rem !important;
    --amplify-text-md:	2rem !important;
    --amplify-text-lg:	2.5rem !important;
    --amplify-text-xl:	3rem !important;
    --amplify-text-xxl: 3.5rem !important;
  }
}

</style>