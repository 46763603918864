<template>
    <div class="sct-contents cmn-inner">
      <div class="inner-box">
        <p>ログインが完了しました。</p>
        <p>以下からお天気ナビゲータをご利用ください。</p> 
        <v-row class="justify-center mt-5">
          <v-btn x-large color="primary" v-on:click="moveToTennavi">
            お天気ナビゲータへ
          </v-btn>
        </v-row>
      </div>
    </div>
</template>

<script>
    
  export default {
    name: 'HelloWorld',

    data: () => ({
      ecosystem: [
        {
          text: 'vuetify-loader',
          href: 'https://github.com/vuetifyjs/vuetify-loader',
        },
        {
          text: 'github',
          href: 'https://github.com/vuetifyjs/vuetify',
        },
        {
          text: 'awesome-vuetify',
          href: 'https://github.com/vuetifyjs/awesome-vuetify',
        },
      ],
      importantLinks: [
        {
          text: 'Documentation',
          href: 'https://vuetifyjs.com',
        },
        {
          text: 'Chat',
          href: 'https://community.vuetifyjs.com',
        },
        {
          text: 'Made with Vuetify',
          href: 'https://madewithvuejs.com/vuetify',
        },
        {
          text: 'Twitter',
          href: 'https://twitter.com/vuetifyjs',
        },
        {
          text: 'Articles',
          href: 'https://medium.com/vuetify',
        },
      ],
      whatsNext: [
        {
          text: 'Explore components',
          href: 'https://vuetifyjs.com/components/api-explorer',
        },
        {
          text: 'Select a layout',
          href: 'https://vuetifyjs.com/getting-started/pre-made-layouts',
        },
        {
          text: 'Frequently Asked Questions',
          href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
        },
      ],
   }),
   mounted() {
      let last_auth_user = localStorage.getItem('CognitoIdentityServiceProvider.6ja9kraj6u8mfl6edb24plq4qc.LastAuthUser');
      console.log(last_auth_user);
      this.info = JSON.parse(localStorage.getItem('CognitoIdentityServiceProvider.6ja9kraj6u8mfl6edb24plq4qc.' + last_auth_user + '.userData'));
      console.log(this.info);
    },
    methods:{
        moveToTennavi(){
          let uid = this.info.UserAttributes[0].Value;
          let username = this.info.Username;
          let url = 'https://s.n-kishou.co.jp/w/charge/register/jmc/login.php?code=' + uid + '&username=' + username;
          window.location.href = url
        }
    }
  }
</script>